import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  ComponentPreview,
  DontDo,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import utilityPreviewData from '../../../../data/previews/utility.yml';
import pageHeroData from '../../../../data/pages/guidelines.yml';
import styles from './styles/utility.module.scss';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Utility"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Utility" />

      <PageNavigation links={['Options', 'Usage', 'Values']} />

      <Section title="Options">
        <Paragraph>
          We have five utility colors to choose from, each with a very specific
          meaning and all distinct from one another.
        </Paragraph>
        <ComponentPreview
          name="UtilityType"
          layout="split"
          previewData={utilityPreviewData.options}>
          <div className={styles.defaultPreview} />
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Accessibility</SectionSubhead>
        <Paragraph>
          Utility colors do not reliably pass accessibility standards. Limit
          their use to graphic flourishes unless explicitly outlined for an
          element, pattern or component.
        </Paragraph>
        <DontDo
          dontText="use full intensity for a background or text."
          doText="use utility colors as accents to draw attention to nearby text."
          imgFilename="colors-utility"
        />
      </Section>

      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query utilityColorsDesign($type: String = "/Utility/") {
    ...colorDataDesign
  }
`;
